@use '../../assets/scss/variables.scss' as *;

.content {
  padding: 2%;

  .grid {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    grid-gap: 50px;

    h1 {
      margin-top: 5px;
    }

    img {
      width: 90%;
      margin: 0 auto;
    }

    .leftHalf {
      grid-column: 1;
    }

    .rightHalf {
      grid-column: 2;
    }
  }
}

.buttonLink {
  text-align: center;
  padding: 15px 0;
}

.transparency {
  .content {
    max-width: 1000px;
    margin: 0 auto;
  }
}

.map {
  padding: 100px 50px 50px 50px;
}

.welcomeArea {
  background-image: url('/src/client/assets/svg/react-app-bg.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0 150px 0;
  position: relative;
  color: white;
  margin-bottom: 0px;

  h1 {
    color: white;
  }

  h2 {
    font-size: 4em;
    text-transform: uppercase;
    text-shadow: rgb(51, 51, 51, 0.5) 5px 5px 10px;
    letter-spacing: -3px;
    color: white;
  }

  .content {
    margin: 0 auto;
    display: block;
    max-width: 1000px;
    // margin-top: 100px;

    @keyframes pulse {
      from {
        transform : scale(1);
        margin-top: 0px;
        margin-left: 0px;
      }
      12% {
        transform : scale(0.85);
        margin-top: -5px;
        margin-left: -5px;
      }
      25% {
        transform : scale(1);
        margin-top: -10px;
        margin-left: 0px;
      }
      37% {
        transform : scale(0.85);
        margin-top: -15px;
        margin-left: 5px;
      }
      50% {
        transform : scale(1);
        margin-top: -20px;
        margin-left: 0px;
      }
      62% {
        transform : scale(0.85);
        margin-top: -15px;
        margin-left: 5px;
      }
      75% {
        transform : scale(1);
        margin-top: -10px;
        margin-left: 0px;
      }
      87% {
        transform : scale(0.85);
        margin-top: -5px;
        margin-left: -5px;
      }
      to {
        transform : scale(1);
        margin-top: 0px;
        margin-left: 0px;
      }
    }

    .rocket {
      -webkit-filter: drop-shadow(3px 3px 10px rgba(0, 0, 0, .5));
      filter: drop-shadow( 3px 3px 10px rgba(0, 0, 0, .5));
      transform: translatey(0px);
	    animation: pulse 6s ease-in infinite;
      position: absolute;
      margin: 0 auto;
      left: calc(50% - 78px);
      top: 180px;
      width: 100px;
      height: 150px;
      background-image: url('/src/client/assets/svg/react-app-rocket.svg');
      background-size: cover;
    }

    .leftHalf {
      h2 {
        text-align: right !important;
        padding-right: 80px;
      }

      div.heading {
        float: right;
        margin-right: 80px;
        font-size: 2.2em;
        letter-spacing: -2px;
        line-height: 1.1;
        color: white;
        text-align: right !important;
      }
    }

    .rightHalf {
      h2 {
        text-align: left !important;
        padding-left: 20px;
      }

      .cloud {
        background-image: url('/src/client/assets/svg/react-app-cloud.svg');
        background-size: contain;
        background-repeat: no-repeat;
        padding: 30px 60px 40px 40px;
        color: $link;
        text-align: center;
        font-size: 1.1em;
        margin-left: 30px;
        width: 350px !important;
        display: block;
        -webkit-filter: drop-shadow(3px 3px 10px rgba(0, 0, 0, .5));
        filter: drop-shadow( 3px 3px 10px rgba(0, 0, 0, .5));
      }
    }
  }
}

.specialOffer {
  background: #efefef; /* Old browsers */
  background: -moz-linear-gradient(top, #f5f5f5 0%, #fff 14%, #fff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #f5f5f5 0%, #fff 14%, #fff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #f5f5f5 0%, #fff 14%, #fff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  padding: 50px 0 60px;

  ul {
    padding: 20px 0 20px 20px;
    list-style-type: disc;
  }

  .content {
    max-width: 1000px;
    margin: 0 auto;
  }
}

.featuresBlock {
  background-color: #efefef;
  text-align: center;

  .content {
    max-width: 1000px;
    margin: 0 auto;

    .grid {
      display: grid;
      grid-template-columns: 0.5fr 0.5fr 0.5fr;
      grid-gap: 30px;
      margin: 25px 0;

      div {
        text-align: center;
        background-color: #fff;
        border: 1px solid #e6f0fa;
        padding: 40px 20px 30px;
        margin: 0;
        box-shadow: $background-input-shadow;

        &:hover {
          transform: scale(1.3);
          transition: transform 0.3s ease-in-out;
          border-radius: 15px;
        }

        h4 {
          margin: 10px 0;
        }

        img {
          width: 80%;
        }
      }
    }
  }
}

.ourExpertise {
  background: url('/src/client/assets/img/expertise/expertise-inner.png') no-repeat center center;
  background-size: cover;

  .content {
    max-width: 1000px;
    margin: 0 auto;
    color: white !important;

    h1 {
      color: white !important;
    }
  }
}

.subscribe {
  background: url('/src/client/assets/img/newsletter-bg.jpg') no-repeat center center;
  background-size: cover;
  min-height: 300px;
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;

  .overlay {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    opacity: 0.8;
  }

  .content {
    z-index: 2;
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    color: white;
    text-align: center;

    input {
      color: black;
    }

    h1 {
      width: 100%;
      font-size: 1.9em;
      padding: 25px 0;
      margin: 0;
      color: white;
    }
  }
}

.modalStyle {
  padding: 0 !important;
  width: 50%;
  min-width: 30%;
  max-width: 600px;
  border-radius: 5px;

  h2.title {
    padding: 20px;
    font-size: 1.8em;
    margin: 0 0 15px 0;
    background-color: white;
    color: white;
  }

  .noticeText {
    padding: 15px;
    margin-top: -15px;
    background-color: #efefef;
  }

  .closeButton {
    width: 30px;
    height: 30px;

    &:hover {
      transform: scale(1.3, 1.3) rotate(360deg);
      transition: all 0.5s linear;
    }
  }

  .closeIcon {
    outline: none;
    cursor: pointer;

    &:hover {
      color: white;
    }
  }

  .modalContent {
    padding: 10px 20px 29px 20px;

    code {
      display: block;
      padding: 5px 20px;
      border-color: #999;
      border-radius: 5px;
      background-color: #eee;
    }

    p {
      padding: 10px 0;
    }

    .buttons {
      text-align: center;
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 800px) {
  .modalStyle {
    width: 100%;
    max-width: 100%;
    height: 95vh;
  }

  .welcomeArea {
    width: 100%;
    background-position: left center;
    text-align: center;
    position: relative;
    background-image: url('/src/client/assets/svg/react-app-bg.svg');
    background-position: 0 center !important;
    background-position-x: 48% !important;
    background-position-y: -90px !important;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 0;

    .h2base {
      margin: 0;
      padding: 0;
      text-align: center !important;
    }

    .content {
      padding-top: 50px;
      position: relative;

      .rocket {
        left: calc(50% - 80px);
        top: 250px;
        width: 160px;
        height: 250px;
      }

      .grid {
        .leftHalf {
          margin: 0 !important;

          h2 {
            @extend .h2base;
          }
        }

        .rightHalf {
          margin: 0 !important;

          h2 {
            @extend .h2base;
          }

          .cloud {
            background: none !important;
            padding: 10px 20px !important;
            margin: 0 !important;
            width: 100% !important;
            transform: rotate(0deg);
            -webkit-filter: none;
            filter: none;
          }
        }

        &:last-child {
          margin-top: 0px;
          color: black;

          .leftHalf {
            padding: 20px 0 0 0;

            .heading {
              display: block !important;
              color: #576A8F;
              font-weight: bold;
              font-size: 2em;
              margin: 0 !important;
              padding: 0;
              text-align: center !important;
              padding: 400px 0 20px 0;
            }
          }
        }
      }
    }
  }

  .ourExpertise {
    background-position: -200px 0;

    .content {
      color: white !important;
      text-align: center;

      .leftHalf {
        width: 60% !important;
      }

      h1 {
        color: white !important;
      }
    }
  }

  .transparency {
    text-align: center;
  }

  .content {
    padding: 15px;

    h1,
    h2,
    h3,
    img {
      text-align: center;
    }

    .grid {
      display: block;
    }
  }

  .featuresBlock {
    .content {
      .grid {
        display: grid;
        grid-template-columns: 1fr !important;
        grid-gap: 10px;
        margin: 15px 0;

        div {
          margin: 0;
          padding: 10px;
          font-size: 0.8em;

          img {
            float: left;
            width: 30%;
            margin: 10px;
          }
        }
      }
    }
  }
}
