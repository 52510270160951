.monthYearPicker {
  position: absolute;
  z-index: 100;
  padding: 15px;
  left: 150px;
  width: 350px;
  transition: all 500ms;
  border-radius: 10px;
  border: 1px solid #efefef;
  background-color: white;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .yearMain {
    display: inline-block;
    width: 100px;
    font-size: 2.5em;
  }

  .padding {
    padding: 10px 0;
  }

  .grid {
    position: relative;
  }

  .increaseYear,
  .decreaseYear {
    display: inline-block;
    padding: 5px;
    width: 40px;
    height: 40px;
    margin: 5px;
    cursor: pointer;
    background-color: #efefef;
    border-radius: 5px;
    vertical-align: top;
  }

  .hover {
    background-color: #efefef;
  }

  .activeMonth {
    vertical-align: top;
    width: 25%;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    text-align: center;
    font-size: 1.5em;
    cursor: pointer;
    color: red;
    font-weight: bold;

    &:hover {
      @extend .hover;
    }
  }

  .oneMonth {
    @extend .activeMonth;

    font-weight: normal;
    color: grey;
    line-height: 50px;
    text-align: center;
    font-size: 1.5em;

    &:hover {
      @extend .hover;
    }

    .unActiveMonth {
      color: #ddd;
      text-decoration: line-through;
      cursor: not-allowed;

      &:hover {
        background-color: white;
      }
    }
  }
}
